import logogif from '../assets/MUNUNO_GIF_LOGos.gif'
import '../styles/App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logogif} className="App-logo" alt="logo" />
        <p className="espacios-parrafos">
          Muy pronto !!!  Coming Soon !!! <br />
             即将推出   곧 온다  近日公開  
        </p>
      </header>
    </div>
  );
}

export default App;
